import {DoubleArrow} from '@mui/icons-material'
import {Autocomplete, Button, TextField} from '@mui/material'
import {installerAssignFilter} from 'pages/install/dataConfig/declearFilters'
import {useEffect, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {getInstallList, notInstallable} from 'utils/apiCalling/api'

const SelectInstallableCustomer = (props: any) => {
  const {set, setRenderStep, step, installSubmit, countCall, clear} = props
  const userInfo = useSelector((state: any) => state?.userInfo?.userInfo)
  const [installableList, setInstallableList] = useState<any>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const getInstallListCall = () => {
    getInstallList(userInfo, 'installCustomer', installerAssignFilter, {
      success: (res: any) => {
        setInstallableList(res)
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }
  const notInstallableCall = () => {
    notInstallable(installSubmit, {
      success: (res: any) => {
        countCall()
        getInstallListCall()
        clear()
      },
      fail: (err: any) => {
        console.log(err)
      },
      loading: (loading: boolean) => {},
    })
  }

  const remindBeforeSelect = (submitData: any) => {
    // Swal.fire({
    //   title: '확인 필요',
    //   text: `${submitData.customerName} 고객, ${
    //     submitData.addressNumber || submitData.addressRoad
    //   }에 설치를 진행합니다.`,
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: '확인',
    //   cancelButtonText: '취소',
    //   willOpen: () => {
    //     const swalPopup = Swal.getPopup() // 팝업 요소 가져오기
    //     if (swalPopup) {
    //       swalPopup.style.zIndex = '6000' // z-index를 직접 설정
    //     }
    //   },
    // }).then((result) => {
    //   if (result.isConfirmed) {
    //     setRenderStep(1)
    //   }
    // })

    setRenderStep(1)
  }

  useEffect(() => {
    if (step === 0) {
      getInstallListCall()
    }
  }, [step])

  return (
    <div className={'row mx-0 justify-content-center pb-3'}>
      <Autocomplete
        disablePortal
        disableCloseOnSelect={false}
        fullWidth
        id='choose-customer'
        options={installableList ?? []}
        className={'px-0'}
        disableClearable={true}
        clearText={'초기화'}
        noOptionsText={'검색 결과가 없습니다.'}
        getOptionLabel={(option: any) =>
          `${option?.customerName} - [ ${option?.customerNumber} ] : ${
            option?.addressNumber ? option?.addressNumber : option?.addressRoad
          }`
        }
        onChange={(e, rowValue: any) => {
          setTimeout(() => {
            if (inputRef.current) {
              inputRef.current.blur()
            }
          }, 100)

          set((prev: any) => ({
            ...prev,
            officeId: rowValue?.officeId,
            customerNumber: rowValue?.customerNumber,
            addressNumber: rowValue?.addressNumber,
            addressRoad: rowValue?.addressRoad,
            customerName: rowValue?.customerName,
            installId: userInfo?.userId,
          }))
        }}
        isOptionEqualToValue={(option, value) =>
          option.customerNumber === value.customerNumber &&
          option.addressNumber === value.addressNumber
        }
        sx={{fontSize: '1.5rem', color: '#fff'}}
        renderInput={(params) => (
          <TextField
            {...params}
            inputRef={(ref) => {
              inputRef.current = ref
            }}
            fullWidth
            label='고객 선택'
            focused
            placeholder={'고객을 검색하거나 선택해주세요.'}
          />
        )}
      />
      {installSubmit?.customerNumber && (
        <>
          <div
            id={'install-info'}
            className={'row gap-3 my-3 fs-5 border border-gray-600 rounded-2 p-4 mx-0'}
            style={{maxWidth: '100%', wordBreak: 'break-word'}}
          >
            <div className={'d-flex flex-column'}>
              <span className={'fw-bolder mb-1'} style={{fontSize: '1.3rem'}}>
                이름
              </span>
              <span style={{fontSize: '1.5rem', color: '#333'}}>{installSubmit.customerName}</span>
            </div>
            <div className={'d-flex flex-column mt-3'}>
              <span className={'fw-bolder mb-1'} style={{fontSize: '1.3rem'}}>
                고객 번호
              </span>
              <span style={{fontSize: '1.5rem', color: '#333'}}>
                {installSubmit.customerNumber}
              </span>
            </div>
            <div className={'d-flex flex-column mt-3'}>
              <span className={'fw-bolder mb-1'} style={{fontSize: '1.3rem'}}>
                주소
              </span>
              <span style={{fontSize: '1.5rem', color: '#333'}}>
                {installSubmit.addressNumber !== ''
                  ? installSubmit.addressNumber
                  : installSubmit.addressRoad}
              </span>
            </div>
          </div>
          <div className={'w-100 px-0 text-end'}>
            <Button variant={'contained'} color={'error'} onClick={() => notInstallableCall()}>
              설치 불가
            </Button>
            <p className={'mt-1 fw-bolder text-danger'}>설치 불가 수용가일 경우에 사용해주세요.</p>
          </div>
        </>
      )}
      <Button
        variant={'contained'}
        color={'primary'}
        style={{position: 'fixed', bottom: '10px'}}
        disabled={!installSubmit?.customerNumber}
        endIcon={<DoubleArrow />}
        onClick={() => {
          remindBeforeSelect(installSubmit)
        }}
      >
        고객 선택 완료
      </Button>
    </div>
  )
}

export default SelectInstallableCustomer
