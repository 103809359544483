import {DoubleArrow} from '@mui/icons-material'
import DownloadDoneIcon from '@mui/icons-material/DownloadDone'
import {Button, Divider, FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import Box from '@mui/material/Box'
import {fabric} from 'fabric'
import {envImagePath} from 'GLOBAL/envVariables'
import {useEffect, useRef, useState} from 'react'
import {imageDownload} from 'utils/apiCalling/api'
import {meterReadEnum} from 'utils/const/globalConst'
import {normalAlert} from 'utils/CustomAlert/Alert'
import {BlueSlider, GreenSlider} from 'utils/designSytem/customSlider'

const ImageEdit = (props: any) => {
  const {set, setRenderStep, step, installSubmit} = props
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState<any>(null)
  const [reWidthRatio, setReWidthRatio] = useState<any>(1)
  const [reHeightRatio, setReHeightRatio] = useState<any>(1)
  useEffect(() => {
    if (step === 2) {
      setTimeout(() => {
        const canvasInstance = new fabric.Canvas('installer-imageEdit', {
          width: 320,
          height: 144,
          top: 150,
        })
        setCanvas(canvasInstance)

        return () => {
          canvasInstance.dispose()
        }
      }, 100)
    }
  }, [step])
  useEffect(() => {
    if (!canvas) return
    if (installSubmit?.filePath) {
      // const imageUrl = 'https://meterarium.com:5280' + installSubmit?.filePath
      const imageUrl = envImagePath + installSubmit?.filePath
      fabric.Image.fromURL(imageUrl, (img: any) => {
        const imgWidth = img.width || 320
        const imgHeight = img.height || 144
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height
        let newWidth = imgWidth
        let newHeight = imgHeight

        // 이미지가 캔버스보다 크면 크기조정
        if (imgWidth > canvasWidth || imgHeight > canvasHeight) {
          const widthRatio = canvasWidth / imgWidth
          const heightRatio = canvasHeight / imgHeight
          setReWidthRatio(imgWidth / canvasWidth)
          setReHeightRatio(imgHeight / canvasHeight)

          newWidth = imgWidth * widthRatio
          newHeight = imgHeight * heightRatio
          console.log(widthRatio, heightRatio)
        }

        canvas.setDimensions({width: newWidth, height: newHeight})

        img.set({
          left: newWidth / 2,
          top: newHeight / 2,
          originX: 'center',
          originY: 'center',
          scaleX: newWidth / imgWidth,
          scaleY: newHeight / imgHeight,
          selectable: false,
        })

        canvas.add(img)

        const horizontalRect = new fabric.Rect({
          left: 60,
          top: 20,
          width: newWidth / 2,
          height: newHeight / 2,
          scaleX: 1,
          scaleY: 1,
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 2,
          selectable: true,
          rotatable: false,
          cornerColor: 'white',
          cornerSize: 15,
        })
        horizontalRect.setControlsVisibility({
          mt: true, // 중앙 위
          mb: true, // 중앙 아래
          ml: true, // 중앙 왼쪽
          mr: true, // 중앙 오른쪽
          bl: true, // 왼쪽 아래
          br: true, // 오른쪽 아래
          tl: true, // 왼쪽 위
          tr: true, // 오른쪽 위
          mtr: false, // 회전 포인트
        })
        canvas.add(horizontalRect)
        canvas.renderAll()
      })
    }
  }, [canvas, installSubmit?.imei])
  /**
   * 이미지 박스 수정
   */
  canvas?.on('object:modified', (e: any) => {
    const img = new fabric.Image()

    const activeObject = e.target
    const rect = activeObject
    const boundingBox = rect.getBoundingRect()
    const xstart = boundingBox.left * reWidthRatio
    const ystart = boundingBox.top * reHeightRatio
    const xend = (boundingBox.left + boundingBox.width) * reWidthRatio
    const yend = (boundingBox.top + boundingBox.height) * reHeightRatio

    set((prev: any) => ({
      ...prev,
      xstart: xstart,
      ystart: ystart,
      xend: xend,
      yend: yend,
    }))
  })

  return (
    <Box className={'row px-0 mx-0 justify-content-center pb-3'}>
      <canvas id='installer-imageEdit' ref={canvasRef} className={'border border-gray-400'} />
      <span className={'text-center text-danger'}>
        이미지 수정을 해야 설치를 완료할 수 있습니다.
      </span>
      <div className={'mt-2'} style={{width: 300}}>
        <Button
          variant={'outlined'}
          color={'primary'}
          fullWidth
          startIcon={<DownloadDoneIcon />}
          onClick={async () =>
            await imageDownload(
              installSubmit?.filePath,
              32,
              255,
              installSubmit?.rotate,
              installSubmit?.serialNumber
            )
          }
        >
          이미지 다운로드
        </Button>
      </div>
      <Box width={'100%'} className={'px-5  mt-5 mb-5 d-flex gap-3 justify-content-center'}>
        <span className={'fs-4 fw-bold align-self-center'}>계량기 자리수를 입력해주세요.</span>
        <input
          className={'w-40px fs-3'}
          type={'number'}
          onChange={async (e) => {
            if (e.target.value === '') return
            if (Number(e.target.value) < 4 || Number(e.target.value) > 9) {
              await normalAlert('자리수는 4~9자리까지 입력 가능합니다.')
              return
            } else {
              set((prev: any) => ({...prev, digit: Number(e.target.value)}))
            }
          }}
          min={4}
          max={10}
        />
        <span className={'fs-4 fw-bold align-self-center'}>자리 수</span>
      </Box>
      <Box width={'100%'} className={'px-1 mb-5 row gap-1 justify-content-center'}>
        <FormControl fullWidth>
          <InputLabel id='meter-type-label'>계량기 종류</InputLabel>
          <Select
            labelId='meter-type-label'
            id='meterType'
            className={installSubmit.recognizedType === 'none' ? 'text-danger' : 'text-dark'}
            label='계량기 종류'
            defaultValue={installSubmit?.recognizedType}
            onChange={(e) => {
              set((prev: any) => ({
                ...prev,
                recognizedType: e.target.value,
              }))
            }}
          >
            <MenuItem value={'none'}>계량기의 종류를 선택해주세요</MenuItem>
            <MenuItem value={'analog'}>1. {meterReadEnum.ANALOG}</MenuItem>
            <MenuItem value={'digital'}>2. {meterReadEnum.DIGITAL}</MenuItem>
            <MenuItem value={'gas'}>3. {meterReadEnum.GAS}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box width={'100%'} className={'px-5 mb-5 border border-2 border-gray-300 rounded-2'}>
        <span className={'text-start fw-bold fs-3 '}>회전 조정</span>
        <BlueSlider
          value={installSubmit?.rotate || 0}
          marks={[
            {value: -180, label: '-180'},
            {value: 0, label: '0'},
            {value: 180, label: '180'},
          ]}
          onChange={(e: any) => {
            const img = canvas.item(0)
            if (img && img.type === 'image') {
              img
                .set({
                  angle: e.target.value,
                  originX: 'center',
                  originY: 'center',
                })
                .setCoords()
              canvas.renderAll()

              const rect = canvas.getObjects().find((obj: any) => obj.type === 'rect')
              const boundingBox = rect.getBoundingRect()
              const xstart = boundingBox.left * reWidthRatio
              const ystart = boundingBox.top * reHeightRatio
              const xend = (boundingBox.left + boundingBox.width) * reWidthRatio
              const yend = (boundingBox.top + boundingBox.height) * reHeightRatio

              const rotate = e.target.value
              set((prev: any) => ({
                ...prev,
                xstart: xstart,
                ystart: ystart,
                xend: xend,
                yend: yend,
                rotate: rotate,
              }))
            }
          }}
          min={-180}
          max={180}
          valueLabelDisplay='auto'
        />
      </Box>
      <Box width={'100%'} className={'px-5 mb-5 border border-2 border-gray-300 rounded-2'}>
        <span className={'fs-3 fw-bolder'}>밝기</span>
        <GreenSlider
          value={installSubmit?.brightness || 32}
          onChange={(e: any) => {
            set((prev: any) => ({
              ...prev,
              brightness: e.target.value,
            }))
          }}
          marks={[
            {value: 0, label: '0'},
            {value: 128, label: '128'},
          ]}
          aria-label='device-brightness'
          valueLabelDisplay='auto'
          max={128}
        />
      </Box>
      <Divider />
      <Button
        variant={'contained'}
        color={'primary'}
        disabled={
          installSubmit?.xstart === null ||
          installSubmit?.digit === null ||
          installSubmit?.recognizedType === 'none'
        }
        style={{position: 'relative', bottom: 0, right: 0}}
        className={'float-end mt-3'}
        endIcon={<DoubleArrow />}
        onClick={() => setRenderStep(3)}
      >
        이미지 및 검침정보 수정완료
      </Button>
    </Box>
  )
}
export default ImageEdit
