import {Backdrop, Modal} from '@mui/material'
import Box from '@mui/material/Box'
import {installModalStyle} from 'pages/install/dataConfig/modalStyle'
import AsStepHeader from 'pages/install/layout/asStepHeader'
import AsDynamicComponent from 'pages/install/modules/asModalComponents/asDynamicComponent'
import AsImageEdit from 'pages/install/modules/asModalComponents/asImageEdit'
import AsInstallComplete from 'pages/install/modules/asModalComponents/asInstallComplete'
import SelectAsInstallabelCustomer from 'pages/install/modules/asModalComponents/selectAsInstallabelCustomer'
import '../../dataConfig/installModal.css'
const AsInstallModalContainer = (props: any) => {
  const {
    open,
    onClose,
    name,
    renderStep,
    set,
    clear,
    setRenderStep,
    asSubmit,
    countCall,
    getPosition,
  } = props
  const renderComponent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <SelectAsInstallabelCustomer
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            asSubmit={asSubmit}
          />
        )
      case 1:
        return (
          <AsDynamicComponent
            type={asSubmit?.resultIdx}
            set={set}
            asSubmit={asSubmit}
            setRenderStep={setRenderStep}
            getPosition={getPosition}
          />
        )
      case 2:
        return (
          <AsImageEdit
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            asSubmit={asSubmit}
          />
        )
      case 3:
        return (
          <AsInstallComplete
            set={set}
            setRenderStep={setRenderStep}
            step={renderStep}
            asSubmit={asSubmit}
            close={onClose}
            clear={clear}
            countCall={countCall}
            getPosition={getPosition}
          />
        )
    }
  }
  return (
    <Modal
      open={open}
      aria-labelledby={`${name}-label`}
      aria-describedby={`${name}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'as-install-modal'}
      className={''}
    >
      <Box sx={installModalStyle} style={{paddingTop: 0}} className={'installer-modal'}>
        <AsStepHeader
          step={renderStep}
          close={onClose}
          clear={clear}
          setRenderStep={setRenderStep}
          set={set}
          asSubmit={asSubmit}
        />
        <div id={'installer-modal-content'} className={'px-4'}>
          {renderComponent(renderStep)}
        </div>
      </Box>
    </Modal>
  )
}

export default AsInstallModalContainer
