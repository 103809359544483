import {LoadingButton} from '@mui/lab'
import {Button, Divider, Stack, TextField} from '@mui/material'
import {yMarker} from 'assets/imageDeclear'
import {fabric} from 'fabric'
import {envImagePath, serverLocalName} from 'GLOBAL/envVariables'
import {useEffect, useRef, useState} from 'react'
import {Map, MapMarker, MapTypeControl} from 'react-kakao-maps-sdk'
import {useSelector} from 'react-redux'
import {installComplete} from 'utils/apiCalling/api'
import {ErrorAlert} from 'utils/CustomAlert/Alert'

const InstallComplete = (props: any) => {
  const {set, installSubmit, step, clear, close, countCall, getPosition} = props
  const [loading, setLoading] = useState<boolean>(false)
  const position = useSelector((state: any) => state?.currentPosition.position)
  const canvasRef = useRef(null)
  const [canvas, setCanvas] = useState<any>(null)
  const mapRef = useRef<any>(null)

  useEffect(() => {
    if (step === 3) {
      setTimeout(() => {
        const canvasInstance = new fabric.Canvas('installer-imageInfo', {
          width: 320,
          height: 144,
        })
        setCanvas(canvasInstance)

        return () => {
          canvasInstance.dispose()
        }
      }, 100)
    }
  }, [step])

  useEffect(() => {
    if (!canvas) return

    if (installSubmit?.filePath) {
      const imageUrl = envImagePath + installSubmit?.filePath
      fabric.Image.fromURL(imageUrl, (img: any) => {
        const imgWidth = img.width || 320
        const imgHeight = img.height || 144
        const canvasWidth = canvas.width
        const canvasHeight = canvas.height
        let newWidth = imgWidth
        let newHeight = imgHeight
        // 이미지가 캔버스보다 크면 크기조정
        if (imgWidth > canvasWidth || imgHeight > canvasHeight) {
          const widthRatio = canvasWidth / imgWidth
          const heightRatio = canvasHeight / imgHeight
          newWidth = imgWidth * widthRatio
          newHeight = imgHeight * heightRatio
        }
        canvas.setDimensions({width: newWidth, height: newHeight})

        img.set({
          left: newWidth / 2,
          top: newHeight / 2,
          originX: 'center',
          originY: 'center',
          scaleX: newWidth / imgWidth,
          scaleY: newHeight / imgHeight,
          selectable: false,
          angle: installSubmit?.rotate,
        })

        canvas.clear()
        canvas.add(img)

        const horizontalRect = new fabric.Rect({
          left: installSubmit?.xstart * (canvasWidth / imgWidth),
          top: installSubmit?.ystart * (canvasHeight / imgHeight),
          width: (installSubmit?.xend - installSubmit?.xstart) * (canvasWidth / imgWidth),
          height: (installSubmit?.yend - installSubmit?.ystart) * (canvasHeight / imgHeight),
          scaleX: 1,
          scaleY: 1,
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 2,
          selectable: false,
          rotatable: false,
          cornerColor: 'white',
          cornerSize: 15,
        })
        canvas.add(horizontalRect)
        canvas.renderAll()
      })
    }
  }, [canvas, installSubmit?.xend])
  const typeName = (name: string) => {
    switch (name) {
      case 'analog':
        return '아날로그 타입'
      case 'digital':
        return '디지털 타입'
      case 'gas':
        return '가스 타입'
    }
  }

  const gpsHandler = (serverName: any) => {
    switch (serverName) {
      // case 'prod':
      // case 'gyeryong':
      // case 'mokpo':
      // case 'suncheon':
      case 'dev':
        return true

      default:
        return false
    }
  }

  const requestComplete = () => {
    switch (serverLocalName) {
      // case 'prod':
      // case 'gyeryong':
      // case 'mokpo':
      // case 'suncheon':
      case 'dev':
        if (installSubmit.longitude === 0 || installSubmit.latitude === 0) {
          return ErrorAlert('위 경도 재검색 후 설치 완료를 진행해주세요')
        } else {
          return installComplete(installSubmit, {
            success: (res: any) => {
              close()
              clear()
              countCall()
            },
            fail: (err: any) => {
              console.log(err)
            },
            loading: (loading: boolean) => {
              setLoading(loading)
            },
          })
        }
      default:
        return installComplete(installSubmit, {
          success: (res: any) => {
            getPosition()
            close()
            clear()
            countCall()
          },
          fail: (err: any) => {
            console.log(err)
          },
          loading: (loading: boolean) => {
            setLoading(loading)
          },
        })
    }
  }

  useEffect(() => {
    set({...installSubmit, latitude: position.latitude, longitude: position.longitude})
  }, [position])
  return (
    <article className={'row gap-4 px-5 justify-content-center pb-3 '}>
      <TextField fullWidth label='고객 번호' value={installSubmit?.customerNumber} />
      <TextField fullWidth label='고객 이름' value={installSubmit?.customerName} />
      <TextField fullWidth label='단말기 S/N' value={installSubmit?.serialNumber} />
      <TextField fullWidth label='단말기 imei' value={installSubmit?.imei} />

      {installSubmit?.deviceType === 'analog' && (
        <>
          <TextField fullWidth label='검침기 자리수' value={installSubmit?.digit} />
          <TextField
            fullWidth
            label='검침기 종류'
            value={typeName(installSubmit?.recognizedType)}
          />
          <canvas id='installer-imageInfo' ref={canvasRef} className={'border border-gray-400'} />
        </>
      )}

      <Stack direction={'row'} className={'px-0'} spacing={2}>
        <TextField fullWidth label='위도' value={installSubmit?.latitude} />
        <TextField fullWidth label='경도' value={installSubmit?.longitude} />
      </Stack>
      {gpsHandler(serverLocalName) && (
        <>
          <div className='gap-4'>
            <Button variant={'contained'} color={'primary'} onClick={() => getPosition()}>
              GPS 재검색
            </Button>
            <strong className='font-lg'> 위, 경도가 0인 경우 GPS 재검색을 진행해주세요</strong>
          </div>

          <Map
            id='map'
            center={{
              lat: position?.latitude,
              lng: position?.longitude,
            }}
            onCreate={(map: any) => (mapRef.current = map)}
            isPanto={false}
            style={{
              width: '100%',
              height: '80vw',
            }}
            level={3}
            mapTypeId={kakao?.maps.MapTypeId.ROADMAP}
            className={' mt-2 rounded p-4'}
            draggable={false}
            zoomable={false}
          >
            <MapTypeControl position={kakao?.maps.ControlPosition.TOPRIGHT} />
            <MapMarker
              position={{
                lat: position?.latitude,
                lng: position?.longitude,
              }}
              draggable={false}
              image={{src: yMarker, size: {width: 33, height: 45}}}
            />
          </Map>
        </>
      )}

      <Divider />
      {loading ? (
        <LoadingButton loading loadingPosition='center' variant='contained'>
          설치 완료
        </LoadingButton>
      ) : (
        <Button variant={'contained'} color={'primary'} fullWidth onClick={() => requestComplete()}>
          설치 완료
        </Button>
      )}
    </article>
  )
}

export default InstallComplete
