import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow'
import {
  Backdrop,
  Button,
  Chip,
  Fade,
  FormControl,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Select,
  Slider,
  TextField,
} from '@mui/material'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import {envBackHost, envImagePath, kakaoAddressAPI, serverLocalName} from 'GLOBAL/envVariables'
import {isMobileDevice} from '_metronic/assets/ts/_utils'
import {yMarker} from 'assets/imageDeclear'
import axios from 'axios'
import dayjs from 'dayjs'
import {fabric} from 'fabric'
import {axiosWithAuth} from 'helper/axiosWithAuth'
import {useEffect, useRef, useState} from 'react'
import {useDaumPostcodePopup} from 'react-daum-postcode'
import {confirmAlert, normalAlert, resultAlert, SuccessAlert} from 'utils/CustomAlert/Alert'
import {CustomWidthTooltip} from 'utils/designSytem/CustomTooltip'
import './imageEdit.css'
import './print.css'

import {Help as HelpIcon} from '@mui/icons-material'
import {DeviceEditImageTooltip} from 'utils/toolTips/toolTipText'

/*********************************************************************
 * 1. Init Libs (all)
 *********************************************************************/

declare global {
  interface Window {
    cv: any
  }
}
declare const cv: any
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: !isMobileDevice() ? '95%' : '90%',
  height: !isMobileDevice() ? '90%' : '90%',
  bgcolor: 'background.paper',
  overflowY: 'auto',
  // border: '2px solid #000',
  borderRadius: '12px',
  boxShadow: 24,
  p: !isMobileDevice() ? 4 : 2,
}
const DeviceDetailInfoModal = (props: any) => {
  const addressInit = {
    latitude: '',
    longitude: '',
  }

  const imageFilterInit = {
    blur: 0,
    sharpening: 0,
    contrast: 1,
    brightness: 1,
    gamma: 1,
  }

  const overLayLineInit = {
    lineColor: '#000000',
    lineWidth: 3,
  }

  const revisionHandler = (serverName: any) => {
    switch (serverName) {
      case 'dev':
      case 'suncheon':
        return true
      default:
        return false
    }
  }

  /*********************************************************************
   * 2-1. useState (detail)
   *********************************************************************/
  const {data, open, onClose, getList} = props
  const rotationRadian = (data?.rotate * Math.PI) / 180
  const cropperRef = useRef<any>(null)
  const [canvas, setCanvas] = useState<any | null>(null)
  const canvasRef = useRef(null)
  const [updateEfosImageInfo, setUpdateEfosImageInfo] = useState<any>(null)
  const [updateEfosInfo, setUpdateEfosInfo] = useState<any>(null)
  const [updateServerCycle, setUpdateServerCycle] = useState<number>(0)
  const [updateRecognizedType, setUpdateRecognizedType] = useState<any>(null)
  const [imageLoaded, setImageLoaded] = useState(false)

  const [address, setAddress] = useState<any>(addressInit)
  const CURRENT_URL = 'https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js'

  /*********************************************************************
   * 3-1. handler (detail)
   *********************************************************************/
  /**
   * 지도 클릭시 다음 웹페이지
   */
  const openMap = useDaumPostcodePopup(CURRENT_URL)

  /**
   *
   * 위 경도 저장 하는 api
   */
  const handleComplete = async (datas: any) => {
    const location = await getAddressCoordinates(datas.roadAddress)
    if (location) {
      axiosWithAuth()
        .put(`${envBackHost}/deviceinfo/geolocate`, {
          imei: data.imei,
          latitude: location.y,
          longitude: location.x,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
            setAddress({
              latitude: location.y,
              longitude: location.x,
            })
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }

  /**
   * 주소 검색시 수용가 주소 입력
   */
  const onClickHandler = () => {
    openMap({onComplete: handleComplete, defaultQuery: data?.addressRoad})
  }

  /**
   * 다음 주소 검색으로 나온 주소로 위 경도 찾기
   */
  const getAddressCoordinates = async (address: any) => {
    try {
      const response = await axios.get(`https://dapi.kakao.com/v2/local/search/address.json`, {
        headers: {
          Authorization: `KakaoAK ${kakaoAddressAPI}`,
        },
        params: {
          query: address,
          analyze_type: 'similar',
        },
      })

      if (response.data.documents && response.data.documents.length > 0) {
        console.log('res', response.data)

        return {
          x: response.data.documents[0].x,
          y: response.data.documents[0].y,
        }
      } else {
        throw new Error('No documents found')
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error)
      return null
    }
  }

  /**
   * 서버 주기 업데이트
   */
  const updateServerCycleHandler = async () => {
    if (Number(updateServerCycle) === 0) {
      await normalAlert('서버 주기는 0이면 안됩니다.')
      return
    }
    if (updateServerCycle === data.serverCycle) {
      await normalAlert('변경 사항이 없습니다.')
      return
    }

    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/deviceinfo`, {
          imei: data.imei,
          latitude: data.latitude,
          longitude: data.longitude,
          temperature: data.temperature,
          serverCycle: updateServerCycle,
        })
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    } else {
      return
    }
  }
  /**
   * 아마도 명암 밝기 수정
   */
  const updateEfosInfoHandler = async (type: string) => {
    if (updateEfosInfo === null) {
      await normalAlert('변경된 데이터가 없습니다.')
      return
    }
    if (await confirmAlert('수정', 'info')) {
      const requestData = {[type]: updateEfosInfo[type], imei: data.imei}
      axiosWithAuth()
        .put(`${envBackHost}/efosinfo/${type}`, requestData)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
  /**
   * 지침부 자리 바꾸기..?
   */
  const updateEfosImageHandler = async () => {
    if (await confirmAlert('수정', 'info')) {
      axiosWithAuth()
        .put(`${envBackHost}/efosimage`, updateEfosImageInfo)
        .then(async (res) => {
          await resultAlert(res.data)
          if (res.data.resultCode === 0) {
            await getList()
          }
        })
        .catch(async (e) => {
          await resultAlert({resultCode: e.response.status})
        })
    }
  }
  /**
   * 계량기 타입 바꾸기
   */
  const updateRecognizedTypeHandler = async () => {
    axiosWithAuth()
      .put(`${envBackHost}/deviceinfo/recognizedtype`, {
        imei: data.imei,
        latitude: data.latitude,
        longitude: data.longitude,
        recognizedType: updateRecognizedType,
      })
      .then(async (res) => {
        await resultAlert(res.data)
        if (res.data.resultCode === 0) {
          getList()
        }
      })
      .catch(async (e) => {
        await resultAlert({resultCode: e.response.status})
      })
  }

  /**
   * esc , x 표시로 닫을때
   */
  const closeHandler = () => {
    onClose()
    getList()
    setAddress(addressInit)
    setUpdateRecognizedType(null)
    setImageLoaded(false)
    cropperRef.current?.cropper.destroy()
    setSelectedView('detail')
    setImageFilter(imageFilterInit)
    resetOverLayFilter()
    setLoadedImage(null)
    setSelectedCVImage(null)
    setOverLayImagePath(null)
  }

  /**
   * 얘는 왜 따로 나와있지 (빨간 영역 박스)
   */
  canvas?.on('object:modified', (e: any) => {
    const activeObject = e.target
    const rect = activeObject
    const boundingBox = rect.getBoundingRect()
    const imageObject = canvas.getObjects().find((obj: any) => obj.type === 'image')
    const xstart = boundingBox.left
    const ystart = boundingBox.top
    const xend = boundingBox.left + boundingBox.width
    const yend = boundingBox.top + boundingBox.height
    const rotate = imageObject?.angle

    setUpdateEfosImageInfo({
      imei: data?.imei,
      xstart,
      ystart,
      xend,
      yend,
      rotate,
    })
  })

  /*********************************************************************
   * 2-1. useState (edit)
   *********************************************************************/
  const [selectedView, setSelectedView] = useState<string>('detail')
  const baseCanvasRef = useRef<HTMLCanvasElement | null>(null) // 원본
  const overlayCanvasRef = useRef<HTMLCanvasElement | null>(null) // 오버레이
  const openCVCanvasRef = useRef<HTMLCanvasElement | null>(null) //opencv
  const resultCanvasRef = useRef<HTMLCanvasElement | null>(null) //최종 이미지
  const [isDrawing, setIsDrawing] = useState(false)
  const [isRectangleMode, setIsRectangleMode] = useState(false) // 사각형 모드
  const [startPoint, setStartPoint] = useState<{x: number; y: number} | null>(null)
  const [overLayImagePath, setOverLayImagePath] = useState<any>(null)
  const [overLayLine, setOverLayLine] = useState<any>(overLayLineInit) // line setting
  const [loadedImage, setLoadedImage] = useState<HTMLImageElement | null>(null) //베이스 이미지
  const [imageFilter, setImageFilter] = useState<any>(imageFilterInit) // image filter
  const [selectedCVImage, setSelectedCVImage] = useState<HTMLImageElement | null>(null)
  const isCvInitialized = useRef<boolean>(false)

  /*********************************************************************
   * 3-1. handler (edit)
   *********************************************************************/
  /** 비동기 이미지 로드 함수 */

  const getImageRevisionData = async () => {
    axiosWithAuth()
      .get(`${envBackHost}/device/imagerevision/${data?.imei}`)
      .then(async (res) => {
        const data = res.data.res
        if (data) {
          setOverLayImagePath(data?.revisionPath)
          setImageFilter({
            ...imageFilter,
            blur: data?.blur,
            sharpening: data?.sharpening,
            contrast: data?.contrast,
            brightness: data?.brightness,
            gamma: data?.gamma,
          })
        }
      })
      .catch(async (e) => {
        console.log('err', e)
      })
  }

  const deleteImageRevisionData = async () => {
    if (data?.revisionPath === null) {
      normalAlert('보정된 이미지가 없습니다')
      return
    }
    axiosWithAuth()
      .delete(`${envBackHost}/device/imagerevision/${data?.imei}`)
      .then(async (res) => {
        const data = res.data

        if (data) {
          resultAlert(data)
          setOverLayImagePath(null)
          setImageFilter(imageFilterInit)
          clearCanvas()
        }
      })
  }

  const postImageRevisionData = async (imageFilter: any) => {
    const overlayCanvas = overlayCanvasRef.current

    if (!overlayCanvas) return
    // canvas 데이터를 Blob으로 변환
    // 회전 값을 원래대로 복원하기 위한 새 canvas 생성
    const tempCanvas = document.createElement('canvas')
    const tempCtx = tempCanvas.getContext('2d')
    if (!tempCtx) return

    tempCanvas.width = overlayCanvas.width
    tempCanvas.height = overlayCanvas.height
    tempCtx.save()
    tempCtx.translate(tempCanvas.width / 2, tempCanvas.height / 2)
    tempCtx.rotate(-rotationRadian) // 회전값을 원래대로 복원
    tempCtx.translate(-tempCanvas.width / 2, -tempCanvas.height / 2)
    tempCtx.drawImage(overlayCanvas, 0, 0, overlayCanvas.width, overlayCanvas.height)
    tempCtx.restore()

    tempCanvas.toBlob(async (blob) => {
      if (!blob) return

      const formData = new FormData()
      formData.append('image', blob, 'overlayImage.png')
      formData.append(
        'imageRevisionModel',
        new Blob([JSON.stringify({...imageFilter, imei: data?.imei})], {type: 'application/json'})
      )

      try {
        const res = await axiosWithAuth().post(`${envBackHost}/device/imagerevision`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        if (res.data) {
          SuccessAlert('레이어를 저장하였습니다.')
          getList()
        }
      } catch (e) {
        console.log('err', e)
      }
    }, 'image/png')
  }

  const drawRectangle = (
    startX: number,
    startY: number,
    endX: number,
    endY: number,
    overlayCtx: CanvasRenderingContext2D,
    baseCtx: CanvasRenderingContext2D
  ) => {
    const rectWidth = endX - startX
    const rectHeight = endY - startY

    // 오버레이 캔버스에 사각형 그리기
    overlayCtx.beginPath()
    overlayCtx.fillStyle = overLayLine.lineColor
    overlayCtx.fillRect(startX, startY, rectWidth, rectHeight)
    overlayCtx.strokeStyle = overLayLine.lineColor
    overlayCtx.lineWidth = overLayLine.lineWidth
    overlayCtx.strokeRect(startX, startY, rectWidth, rectHeight)
    overlayCtx.closePath()

    // 원본 캔버스에 사각형 그리기
    baseCtx.beginPath()
    baseCtx.fillStyle = overLayLine.lineColor
    baseCtx.fillRect(startX, startY, rectWidth, rectHeight)
    baseCtx.strokeStyle = overLayLine.lineColor
    baseCtx.lineWidth = overLayLine.lineWidth
    baseCtx.strokeRect(startX, startY, rectWidth, rectHeight)
    baseCtx.closePath()
  }

  /**
   * 그림판 기능 그릴때 클릭지점 구하기
   */
  const draw = (event: React.MouseEvent<HTMLCanvasElement>) => {
    if (!isDrawing || !overlayCanvasRef.current || !baseCanvasRef.current) return

    const overlayCanvas = overlayCanvasRef.current
    const baseCanvas = baseCanvasRef.current

    const overlayCtx = overlayCanvas.getContext('2d')
    const baseCtx = baseCanvas.getContext('2d')

    if (!overlayCtx || !baseCtx) return

    const rect = overlayCanvas.getBoundingClientRect()
    const scaleX = overlayCanvas.width / rect.width
    const scaleY = overlayCanvas.height / rect.height

    const x = event.nativeEvent.offsetX * scaleX
    const y = event.nativeEvent.offsetY * scaleY

    if (isRectangleMode && startPoint) {
      const {x: startX, y: startY} = startPoint

      baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height)

      if (baseCanvas && baseCtx && loadedImage) {
        baseCanvas.width = loadedImage.width
        baseCanvas.height = loadedImage.height
        drawRotatedImage(baseCanvas, baseCtx, loadedImage, rotationRadian)
        baseCtx.drawImage(overlayCanvas, 0, 0, overlayCanvas.width, overlayCanvas.height)
      }

      baseCtx.beginPath()
      baseCtx.strokeStyle = overLayLine.lineColor
      baseCtx.lineWidth = overLayLine.lineWidth
      baseCtx.strokeRect(startX, startY, x - startX, y - startY)
      baseCtx.closePath()
    } else {
      // 기존 선 그리기 모드
      overlayCtx.strokeStyle = overLayLine.lineColor
      overlayCtx.lineWidth = overLayLine.lineWidth
      overlayCtx.lineCap = 'round'
      overlayCtx.lineTo(x, y)
      overlayCtx.stroke()

      baseCtx.strokeStyle = overLayLine.lineColor
      baseCtx.lineWidth = overLayLine.lineWidth
      baseCtx.lineCap = 'round'
      baseCtx.lineTo(x, y)
      baseCtx.stroke()
    }
  }
  /**
   * 그림판 기능 그리는중
   */
  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const overlayCanvas = overlayCanvasRef.current
    const baseCanvas = baseCanvasRef.current
    if (!overlayCanvas || !baseCanvas) return

    const overlayCtx = overlayCanvas.getContext('2d')
    const baseCtx = baseCanvas.getContext('2d')
    if (!overlayCtx || !baseCtx) return

    // 캔버스와 이미지의 비율 계산
    const rect = overlayCanvas.getBoundingClientRect()
    const scaleX = overlayCanvas.width / rect.width
    const scaleY = overlayCanvas.height / rect.height

    // 실제 좌표 계산
    const x = event.nativeEvent.offsetX * scaleX
    const y = event.nativeEvent.offsetY * scaleY

    if (isRectangleMode) {
      setStartPoint({x, y})
    } else {
      overlayCtx.beginPath()
      overlayCtx.moveTo(x, y)
      baseCtx.beginPath()
      baseCtx.moveTo(x, y)
    }

    setIsDrawing(true)
  }

  /**
   * 그림판 기능 그리기 끝났을때 (마우스 unclick)
   */
  const stopDrawing = (event: React.MouseEvent<HTMLCanvasElement>) => {
    setIsDrawing(false)

    const overlayCanvas = overlayCanvasRef.current
    const baseCanvas = baseCanvasRef.current
    if (!overlayCanvas || !baseCanvas) return

    const overlayCtx = overlayCanvas.getContext('2d')
    const baseCtx = baseCanvas.getContext('2d')
    if (!overlayCtx || !baseCtx) return

    overlayCtx.closePath()
    baseCtx.closePath()

    if (isRectangleMode && startPoint) {
      const rect = overlayCanvas.getBoundingClientRect()
      const scaleX = overlayCanvas.width / rect.width
      const scaleY = overlayCanvas.height / rect.height
      const endX = event.nativeEvent.offsetX * scaleX
      const endY = event.nativeEvent.offsetY * scaleY
      const {x: startX, y: startY} = startPoint

      drawRectangle(startX, startY, endX, endY, overlayCtx, baseCtx)
      setStartPoint(null)
    }
  }

  const clearCanvas = () => {
    const overlayCanvas = overlayCanvasRef.current
    const baseCanvas = baseCanvasRef.current
    if (!overlayCanvas || !baseCanvas) return

    const overlayCtx = overlayCanvas.getContext('2d')
    const baseCtx = baseCanvas.getContext('2d')
    if (!overlayCtx || !baseCtx) return

    // 캔버스 초기화
    overlayCtx.clearRect(0, 0, overlayCanvas.width, overlayCanvas.height)
    baseCtx.clearRect(0, 0, baseCanvas.width, baseCanvas.height)

    if (loadedImage) {
      if (loadedImage.width > 0 && loadedImage.height > 0) {
        drawRotatedImage(baseCanvas, baseCtx, loadedImage, rotationRadian)
      } else {
        console.error('Invalid loaded image dimensions.')
      }
    }

    combineLayers()
  }

  /**
   * 레이어 및 필터 설정 DB 저장
   */
  const saveFilterSetting = () => {
    postImageRevisionData(imageFilter)
  }

  /**
   * openCV 라이브러리 출력
   */
  useEffect(() => {
    /**
     * 애먹은 부분..
     * yarn add opencv.js 이후
     * cp node_modules/opencv.js/opencv.js public/js/ 정적 파일 이동
     *
     */
    const initializeOpenCVEffect = () => {
      if (typeof cv !== 'undefined' && !isCvInitialized.current) {
        if (cv.hasOwnProperty('onRuntimeInitialized')) {
          cv.onRuntimeInitialized = () => {
            console.log('OpenCV.js initialized')
            isCvInitialized.current = true
          }
        } else {
          console.log('OpenCV.js is already initialized')
          isCvInitialized.current = true
        }
      } else if (typeof cv === 'undefined') {
        console.error('OpenCV.js not loaded')
      }
    }

    initializeOpenCVEffect()

    const intervalId = setInterval(() => {
      if (!isCvInitialized.current && typeof cv !== 'undefined') {
        initializeOpenCVEffect()
      }
    }, 500)

    return () => clearInterval(intervalId)
  }, [])

  // const initializeOpenCV = () => {
  //   if (!isCvInitialized.current) {
  //     // 기존 스크립트 로드 여부 확인
  //     const existingScript = document.querySelector(
  //       "script[src='https://docs.opencv.org/4.x/opencv.js']"
  //     )

  //     // const existingScript = document.querySelector(
  //     //   "script[src='/js/opencv.js']"
  //     // )

  //     if (!existingScript) {
  //       const script = document.createElement('script')
  //       script.src = 'https://docs.opencv.org/4.x/opencv.js'
  //       // script.src = '/js/opencv.js'
  //       script.async = true

  //       script.onload = () => {
  //         console.log('OpenCV.js script loaded. Waiting for initialization...')
  //         if (window.cv) {
  //           window.cv.onRuntimeInitialized = () => {
  //             console.log('OpenCV.js initialized and ready to use.')
  //             isCvInitialized.current = true
  //           }
  //         } else {
  //           console.error('OpenCV.js failed to initialize: cv object not found.')
  //         }
  //       }

  //       script.onerror = () => {
  //         console.error('Failed to load OpenCV.js. Check network or CORS settings.')
  //       }

  //       document.body.appendChild(script)
  //     } else {
  //       console.log('OpenCV.js is already loaded.')
  //       if (window.cv && window.cv.onRuntimeInitialized) {
  //         window.cv.onRuntimeInitialized = () => {
  //           console.log('OpenCV.js initialized and ready to use.')
  //           isCvInitialized.current = true
  //         }
  //       } else {
  //         console.error('OpenCV.js script exists but cv object not initialized.')
  //       }
  //     }
  //   }
  // }

  // /**
  //  * openCV 불러오기
  //  */
  // useEffect(() => {
  //   initializeOpenCV()
  // }, [])

  /**
   * 필터 초기화
   */
  const changeImageFilter = (name: string, value: any) => {
    setImageFilter({...imageFilter, [name]: value})
  }
  /**
   * 필터 기능 입히기
   */
  const applyImageEffects = (img: HTMLImageElement) => {
    if (!isCvInitialized.current || !openCVCanvasRef.current || !img) {
      return
    }

    const canvas = openCVCanvasRef.current
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      console.error('Canvas context not found')
      return
    }
    canvas.width = img.width
    canvas.height = img.height
    drawRotatedImage(canvas, ctx, img, rotationRadian)

    const src = cv.imread(canvas)
    let dst = new cv.Mat()
    // 블러
    if (imageFilter.blur > 0) {
      const kernelSize = imageFilter.blur % 2 === 1 ? imageFilter.blur : imageFilter.blur + 1 // 홀수 커널 크기 필요
      cv.GaussianBlur(src, dst, new cv.Size(kernelSize, kernelSize), 0)
    } else {
      dst = src.clone()
    }
    // 샤프닝 효과 적용 (언샤프 마스크)
    if (imageFilter.sharpening > 0) {
      let sharpened = new cv.Mat()
      let gaussianBlur = new cv.Mat()
      cv.GaussianBlur(dst, gaussianBlur, new cv.Size(0, 0), imageFilter.sharpening)
      cv.addWeighted(dst, 1.5, gaussianBlur, -0.5, 0, sharpened)
      dst = sharpened
      gaussianBlur.delete()
    }

    // 명암 밝기
    dst.convertTo(dst, -1, imageFilter.contrast, imageFilter.brightness * 128 - 128)
    /**
     * 감마 (감마 기능이 없는 opencv.js 으로 보여 수동 감마 기능으로 대체)
     * 즉 이미지 인식 파트 감마와 100% 같이 동작 안할 여부 있음 고려 필요
     **/

    if (imageFilter.gamma !== 1) {
      const gammaLUT = createGammaLUT(imageFilter.gamma)
      const correctedDst = applyLUT(dst, gammaLUT)
      dst.delete()
      dst = correctedDst
    }

    cv.imshow(canvas, dst)

    /**
     * opencv.js 사용시 메모리를 사용하니 장시간 이용시 메모리 누수 가능성
     * delete로 초기화
     */
    src.delete()
    dst.delete()
  }

  /**
   * 고해상도 이미지 처리시 문제 발생
   * 이미지 자체가 크지 않기때문에 문제없이 사용 가능
   */
  const applyLUT = (src: any, lutArray: number[]) => {
    const dst = new cv.Mat(src.rows, src.cols, src.type())

    for (let row = 0; row < src.rows; row++) {
      for (let col = 0; col < src.cols; col++) {
        const srcPixel = src.ucharPtr(row, col)
        const dstPixel = dst.ucharPtr(row, col)

        for (let channel = 0; channel < src.channels(); channel++) {
          dstPixel[channel] = lutArray[srcPixel[channel]]
        }
      }
    }

    return dst
  }
  /**
   * 감마 생성
   */
  const createGammaLUT = (gamma: number) => {
    const lut = new Array(256)
    for (let i = 0; i < 256; i++) {
      lut[i] = Math.min(255, Math.max(0, Math.pow(i / 255.0, gamma) * 255.0))
    }
    return lut
  }

  const resetImageFilter = () => {
    setImageFilter(imageFilterInit)
    if (selectedCVImage) {
      applyImageEffects(selectedCVImage)
    }
  }

  const combineLayers = () => {
    const resultCanvas = resultCanvasRef.current
    const filterCanvas = openCVCanvasRef.current
    const overlayCanvas = overlayCanvasRef.current

    if (!resultCanvas || !filterCanvas || !overlayCanvas) {
      console.error('캔버스가 준비되지 않았습니다.')
      return
    }

    if (
      filterCanvas.width > 0 &&
      filterCanvas.height > 0 &&
      overlayCanvas.width > 0 &&
      overlayCanvas.height > 0
    ) {
      const resultCtx = resultCanvas.getContext('2d')
      const filterCtx = filterCanvas.getContext('2d')
      const overlayCtx = overlayCanvas.getContext('2d')

      if (!resultCtx || !filterCtx || !overlayCtx) {
        console.error('캔버스 컨텍스트를 가져오지 못했습니다.')
        return
      }

      resultCanvas.width = filterCanvas.width
      resultCanvas.height = filterCanvas.height
      resultCtx.clearRect(0, 0, resultCanvas.width, resultCanvas.height)

      resultCtx.drawImage(filterCanvas, 0, 0)

      resultCtx.globalAlpha = 1
      resultCtx.drawImage(overlayCanvas, 0, 0)
    } else {
      console.error('Invalid dimensions in one or more canvases.')
    }
  }

  const drawRotatedImage = (
    canvas: any,
    ctx: CanvasRenderingContext2D,
    image: any,
    rotationRadian: any
  ) => {
    ctx.save()
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate(rotationRadian)

    if (!image.complete || image.naturalWidth === 0 || image.naturalHeight === 0) {
      console.error('이미지가 올바르게 로드되지 않았습니다.')
      return
    }
    ctx.drawImage(image, -image.width / 2, -image.height / 2, image.width, image.height)
    ctx.restore()
  }

  const resetOverLayFilter = () => {
    setOverLayLine(overLayLineInit)
    combineLayers()
  }

  const changeOverLayLineHandler = (name: string, value: any) => {
    setOverLayLine({...overLayLine, [name]: value})
  }

  /*********************************************************************
   * 5. UseEffect
   *********************************************************************/

  /**
   * 검침 이미지 보정 탭 이미지들 캔버스에 load 및 표현
   * croossOrigin 설정으로 http 환경, CORS 에러 방지
   */
  useEffect(() => {
    const imageURL = envImagePath + (data?.filePath || '')
    if (!data?.filePath) return

    let overLayURL = data?.revisionPath
      ? `${envImagePath}/device/imagerevision/${data.imei}/image`
      : ''

    const img = new Image()
    img.src = imageURL
    img.crossOrigin = 'anonymous'

    const overLayimg = new Image()
    overLayimg.crossOrigin = 'anonymous'
    if (overLayURL && overLayURL !== '') {
      overLayimg.src = overLayURL

      // 이미지 로드 실패 시 처리
      overLayimg.onerror = () => {
        console.error(
          `Failed to load overlay image from URL: ${overLayURL}. Using placeholder image.`
        )
        overLayimg.src =
          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ibSUuoAAAAASUVORK5CYII='
      }
    } else {
      // 기본 이미지 설정
      overLayimg.src =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/wcAAwAB/ibSUuoAAAAASUVORK5CYII='
    }

    img.onload = () => {
      setLoadedImage(img)
      setSelectedCVImage(img)
      const baseCanvas = baseCanvasRef.current
      const openCVCanvas = openCVCanvasRef.current
      if (!baseCanvas || !openCVCanvas) return

      const baseCtx = baseCanvas.getContext('2d')
      const openCVCtx = openCVCanvas.getContext('2d')

      if (!baseCtx || !openCVCtx) return

      baseCanvas.width = img.width
      baseCanvas.height = img.height
      openCVCanvas.width = img.width
      openCVCanvas.height = img.height

      const overlayCanvas = overlayCanvasRef.current
      if (!overlayCanvas) return
      const overlayCtx = overlayCanvas.getContext('2d')
      if (!overlayCtx) return

      overlayCanvas.width = img.width
      overlayCanvas.height = img.height

      console.log('overlay', overLayimg)
      drawRotatedImage(baseCanvas, baseCtx, img, rotationRadian)
      drawRotatedImage(overlayCanvas, overlayCtx, overLayimg, rotationRadian)
      drawRotatedImage(openCVCanvas, openCVCtx, img, rotationRadian)
    }

    img.onerror = (error) => {
      console.error('이미지 로드 실패:', error)
    }
  }, [data, selectedView])

  /**
   * 이미지에 필터 적용
   */
  useEffect(() => {
    if (isCvInitialized.current && selectedCVImage) {
      applyImageEffects(selectedCVImage)
    }
  }, [imageFilter, selectedCVImage, isCvInitialized.current])
  // useEffect(() => {}, [data])
  /**
   * detail 페이지 캔버스
   */
  useEffect(() => {
    setTimeout(() => {
      const canvasInstance = new fabric.Canvas('imageEdit', {
        width: 320,
        height: 144,
        padding: 5,
        top: 150,
      })
      setCanvas(canvasInstance)
      setUpdateServerCycle(data?.serverCycle)
      setUpdateEfosImageInfo({
        ...updateEfosImageInfo,
        imei: data?.imei,
        xstart: data?.xfrom,
        ystart: data?.yfrom,
        xend: data?.xto,
        yend: data?.yto,
        rotate: data?.rotate,
      })

      return () => {
        canvasInstance.dispose()
      }
    }, 100)
  }, [data, selectedView])

  /**
   * detail 페이지 캔버스 2
   */
  useEffect(() => {
    if (!canvas) return
    if (data) {
      const imageUrl = envImagePath + (data?.filePath || '')
      // const slice = data?.filePath?.split('/')
      fabric.Image.fromURL(imageUrl, (img: any) => {
        const imgWidth = img ? img.width : 320
        const imgHeight = img ? img.height : 144
        if (imgWidth > canvas.width || imgHeight > canvas.height) {
          canvas.setDimensions({width: imgWidth, height: imgHeight})
        }

        img.set({
          left: canvas.width / 2,
          top: canvas.height / 2,
          width: imgWidth,
          height: imgHeight,
          originX: 'center',
          originY: 'center',
          selectable: false,
          angle: data?.rotate,
        })
        canvas.add(img)

        const horizontalRect = new fabric.Rect({
          left: data?.xfrom !== 0 ? img.left + (data?.xfrom - imgWidth / 2) : 0,
          top: data?.yfrom !== 0 ? img.top + (data?.yfrom - imgHeight / 2) : 0,
          width: data?.xto !== 0 ? data?.xto - data?.xfrom : imgWidth - 3,
          height: data?.yto !== 0 ? data?.yto - data?.yfrom : imgHeight - 3,
          scaleX: 1,
          scaleY: 1,
          fill: 'transparent',
          stroke: 'red',
          strokeWidth: 2,
          selectable: true,
          rotatable: false,
          cornerColor: 'white',
          cornerSize: 15,
        })
        horizontalRect.setControlsVisibility({
          mt: true, // 중앙 위
          mb: true, // 중앙 아래
          ml: true, // 중앙 왼쪽
          mr: true, // 중앙 오른쪽
          bl: true, // 왼쪽 아래
          br: true, // 오른쪽 아래
          tl: true, // 왼쪽 위
          tr: true, // 오른쪽 위
          mtr: false, // 회전 포인트
        })

        canvas.add(horizontalRect)
        canvas.renderAll()
      })
    }
  }, [canvas, data, selectedView])

  /**
   * detail 지도 useEffect
   */

  useEffect(() => {
    const latitude = address?.latitude || data?.latitude
    const longitude = address?.longitude || data?.longitude

    if (latitude && longitude) {
      setTimeout(() => {
        let container = document?.getElementById('dashboard-detail-map')
        if (container) {
          const map = new window.kakao.maps.Map(container, {
            center: new window.kakao.maps.LatLng(latitude, longitude),
            mapTypeId: window.kakao.maps.MapTypeId.HYBRID,
            level: 1,
            draggable: false,
            scrollwheel: false,
            zoomable: false,
          })

          const markerPosition = new window.kakao.maps.LatLng(latitude, longitude)
          const marker = new window.kakao.maps.Marker({
            position: markerPosition,
            image: new window.kakao.maps.MarkerImage(yMarker, new window.kakao.maps.Size(40, 59)),
          })

          marker.setMap(map)
        }
      }, 300)
    }
  }, [data, address, selectedView])

  /*
   * 컴포넌트 마운트 후 및 필터 또는 레이어 변경 시 결합
   */
  useEffect(() => {
    if (loadedImage && openCVCanvasRef.current && overlayCanvasRef.current) {
      combineLayers()
    }
  }, [imageFilter, isDrawing, overlayCanvasRef, selectedView, loadedImage])

  useEffect(() => {
    if (revisionHandler(serverLocalName)) {
      getImageRevisionData()
    }
  }, [data])

  return (
    <Modal
      open={open}
      onClose={() => {
        closeHandler()
      }}
      aria-labelledby={`${window.location.pathname}-label`}
      aria-describedby={`${window.location.pathname}-description`}
      closeAfterTransition
      slots={{backdrop: Backdrop}}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      id={'printTarget'}
      className={'printTarget'}
    >
      <Box sx={style}>
        <>
          <div>
            <div>
              <div className=''>
                <div className={'w-100 d-flex justify-content-between'}>
                  <div className={'d-flex'}>
                    <div
                      onClick={() => {
                        setSelectedView('detail')
                      }}
                    >
                      <Chip
                        label={
                          <span>
                            <strong className={'text-primary'}>{data?.customerName}</strong> 단말기
                            및 검침기 상세정보
                          </span>
                        }
                        variant={'outlined'}
                        sx={{
                          cursor: 'pointer',
                          fontSize: '20px',
                          fontWeight: 'bold',
                          backgroundColor:
                            selectedView === 'detail' ? 'rgba(0, 123, 255, 0.1)' : 'transparent',
                          borderColor:
                            selectedView === 'detail' ? '#007BFF' : 'rgba(0, 0, 0, 0.23)',
                          color: selectedView === 'detail' ? '#007BFF' : 'inherit',
                        }}
                      />
                    </div>
                    {revisionHandler(serverLocalName) && (
                      <div
                        onClick={() => {
                          setSelectedView('edit')
                        }}
                        className={'mx-2'}
                      >
                        <Chip
                          label={<span>검침 이미지 보정</span>}
                          variant={'outlined'}
                          sx={{
                            cursor: 'pointer',
                            fontSize: '20px',
                            fontWeight: 'bold',
                            backgroundColor:
                              selectedView === 'edit' ? 'rgba(0, 123, 255, 0.1)' : 'transparent',
                            borderColor:
                              selectedView === 'edit' ? '#007BFF' : 'rgba(0, 0, 0, 0.23)',
                            color: selectedView === 'edit' ? '#007BFF' : 'inherit',
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <CancelPresentationIcon
                    sx={{fontSize: '40px', cursor: 'pointer'}}
                    color={'error'}
                    onClick={() => {
                      closeHandler()
                    }}
                  />
                </div>
                {selectedView === 'detail' && (
                  <div className=''>
                    {data && (
                      <>
                        <div className={'d-flex gap-6 col'}>
                          <div className={'col'}>
                            <div className={'row mx-0 gap-2'}>
                              <div className={' border border-1 border-gray-400 shadow-sm px-0'}>
                                <Paper
                                  className={'p-4 border-bottom border-1 border-gray-400 '}
                                  square
                                >
                                  <h2>단말기 정보</h2>
                                </Paper>
                                <Paper className={'p-4 '} square>
                                  <div className={'row gap-2'}>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 IMEI
                                      </h2>

                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.imei}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 S/N
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.serialNumber}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 업데이트 대기
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          data.deviceApplyStatus === true
                                            ? '업데이트 대기 중'
                                            : '업데이트 없음'
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>

                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 타입
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.deviceTypeName}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 펌웨어
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.deviceVersion}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 통신상태
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.deviceStatus === true ? '정상' : '점검'}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                        error={data?.deviceStatus === false}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 단말기 통신실패
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data.deviceCommFailCount + '회'}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                        error={data?.deviceStatus === false}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 배터리
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.batteryLevel + '%'}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 최초 설치일자
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={dayjs(data?.installAt).format('YYYY년 M월 DD일')}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 서버주기
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        style={{width: 319}}
                                        className={'mx-2 '}
                                        defaultValue={Number(data?.serverCycle)}
                                        type={'number'}
                                        onChange={(e: any) => {
                                          setUpdateServerCycle(e.target.value)
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton aria-label='resetBtn' edge='end'>
                                                <Button
                                                  variant={'outlined'}
                                                  size={'large'}
                                                  sx={{width: 50, height: 50}}
                                                  onClick={() => updateServerCycleHandler()}
                                                  disabled={data?.deviceType === 'digital'}
                                                >
                                                  수정
                                                </Button>
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <div className={'d-flex gap-3'}>
                                        <h2 className={'d-flex align-items-center gap-2'}>
                                          <DoubleArrowIcon /> 위도
                                        </h2>
                                        <Button
                                          variant={'outlined'}
                                          size={'large'}
                                          onClick={() => onClickHandler()}
                                        >
                                          위치수정
                                        </Button>
                                      </div>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          address.latitude === ''
                                            ? data?.latitude
                                            : address.latitude
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 경도
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          address.longitude === ''
                                            ? data?.longitude
                                            : address.longitude
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Paper>
                              </div>
                            </div>
                          </div>
                          <div className={'col'}>
                            <div className={'row gap-3'}>
                              <div
                                id='dashboard-detail-map'
                                style={{width: '100%', height: 600, cursor: 'pointer'}}
                                onClick={() => {
                                  window.open(
                                    `https://m.map.kakao.com/scheme/search?q=${data.addressRoad}&center=me`,
                                    '_blank'
                                  )
                                }}
                                className={'rounded-2 border-gray-400 border border-1'}
                              />
                              <div className={'text-end'}>
                                <span className={'text-danger fw-bold text-end'}>
                                  지도를 클릭하면 카카오맵으로 이동합니다.
                                </span>
                              </div>
                              <div className={'h-100'}>
                                <div className={'row'}>
                                  <div className={'mt-2'}>
                                    <h2>검침 인식영역</h2>
                                    <div
                                      className={
                                        'w-100 border border-gray-400 border-2 rounded-2 pt-5'
                                      }
                                    >
                                      <div
                                        className={
                                          'row px-0 mx-0 justify-content-center imageCenter'
                                        }
                                      >
                                        <div>
                                          <canvas
                                            id='imageEdit'
                                            ref={canvasRef}
                                            style={{zIndex: 1000}}
                                            className={'px-0 mx-0'}
                                          />
                                        </div>
                                        <div>
                                          <Slider
                                            style={{width: 320, marginTop: 30}}
                                            defaultValue={data?.rotate}
                                            onChange={(e: any) => {
                                              // 이미지 객체를 참조합니다.
                                              const img = canvas.item(0) // 이미지가 canvas의 첫 번째 아이템이라고 가정합니다.

                                              // 이미지 객체의 angle 속성을 변경하고, 회전의 기준점을 이미지의 중앙으로 설정합니다.
                                              if (img && img.type === 'image') {
                                                img
                                                  .set({
                                                    angle: e.target.value,
                                                    originX: 'center',
                                                    originY: 'center',
                                                  })
                                                  .setCoords()
                                                canvas.renderAll()

                                                const rect = canvas
                                                  .getObjects()
                                                  .find((obj: any) => obj.type === 'rect')
                                                const boundingBox = rect.getBoundingRect()
                                                const xstart = boundingBox.left
                                                const ystart = boundingBox.top
                                                const xend = boundingBox.left + boundingBox.width
                                                const yend = boundingBox.top + boundingBox.height
                                                // const xstart = rect.left
                                                // const ystart = rect.top
                                                // const xend = rect.left + rect.width
                                                // const yend = rect.top + rect.height
                                                let rotate = e.target.value

                                                setUpdateEfosImageInfo({
                                                  ...updateEfosImageInfo,
                                                  imei: data?.imei,
                                                  xstart,
                                                  ystart,
                                                  xend,
                                                  yend,
                                                  rotate: rotate,
                                                })
                                              }
                                            }}
                                            min={-180}
                                            max={180}
                                            valueLabelDisplay='auto'
                                          />
                                        </div>
                                      </div>
                                      <div className={'my-3 text-center '}>
                                        <Button
                                          variant={'outlined'}
                                          size={'large'}
                                          className={'fs-3'}
                                          onClick={() => updateEfosImageHandler()}
                                          // disabled={!imageLoaded}
                                        >
                                          수정
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className={'col '}>
                            <div className={'row mx-0 gap-2 align-self-stretch'}>
                              <div
                                className={
                                  ' border border-1 border-gray-400 shadow-sm px-0 align-self-stretch'
                                }
                              >
                                <Paper
                                  className={
                                    'p-4 border-bottom border-1 border-gray-400 align-self-stretch '
                                  }
                                  square
                                >
                                  <h2>검침기 정보</h2>
                                </Paper>
                                <Paper className={'p-4 '} square>
                                  <div className={'row gap-2'}>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 검침기 S/N
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.meterReadSerialNumber}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 계량기 통신상태
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.efosStatus === true ? '정상' : '점검'}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 미터링 통신실패
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          data?.meterReadCommFailCount === undefined
                                            ? null
                                            : data?.meterReadCommFailCount + '회'
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 검침기 펌웨어
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={'YIT-EFOS'}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 검침기 설치일시
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data?.meterReadInstallDate}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 내구연한 ( 단위 : 년 )
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={data.remainDurability}
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 이미지 명암
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        style={{width: 319}}
                                        className={'mx-2 '}
                                        defaultValue={data?.contrast}
                                        onChange={async (e) => {
                                          setUpdateEfosInfo({
                                            ...updateEfosInfo,
                                            contrast: e.target.value,
                                          })
                                        }}
                                        disabled={data?.deviceType === 'digital'}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton aria-label='resetBtn' edge='end'>
                                                <Button
                                                  variant={'outlined'}
                                                  size={'large'}
                                                  sx={{width: 50, height: 50}}
                                                  onClick={() => updateEfosInfoHandler('contrast')}
                                                  disabled={data?.deviceType === 'digital'}
                                                >
                                                  수정
                                                </Button>
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon />
                                        이미지 밝기
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        style={{width: 319}}
                                        className={'mx-2 '}
                                        defaultValue={data?.brightness}
                                        disabled={data?.deviceType === 'digital'}
                                        onChange={async (e) => {
                                          setUpdateEfosInfo({
                                            ...updateEfosInfo,
                                            brightness: e.target.value,
                                          })
                                        }}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton aria-label='resetBtn' edge='end'>
                                                <Button
                                                  variant={'outlined'}
                                                  size={'large'}
                                                  sx={{width: 50, height: 50}}
                                                  disabled={data?.deviceType === 'digital'}
                                                  onClick={() =>
                                                    updateEfosInfoHandler('brightness')
                                                  }
                                                >
                                                  수정
                                                </Button>
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon />
                                        이미지 회전
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        className={'mx-2 bg-gray-300'}
                                        value={
                                          data?.rotate === undefined ? null : data.rotate + '°'
                                        }
                                        inputProps={{
                                          readOnly: true,
                                        }}
                                      />
                                    </div>
                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 지침부 자릿수
                                      </h2>
                                      <TextField
                                        variant={'outlined'}
                                        style={{width: 319}}
                                        className={'mx-2 '}
                                        defaultValue={data?.digit}
                                        onChange={async (e) => {
                                          setUpdateEfosInfo({
                                            ...updateEfosInfo,
                                            digit: e.target.value,
                                          })
                                        }}
                                        disabled={data?.deviceType === 'digital'}
                                        InputProps={{
                                          endAdornment: (
                                            <InputAdornment position='end'>
                                              <IconButton aria-label='resetBtn' edge='end'>
                                                <Button
                                                  variant={'outlined'}
                                                  size={'large'}
                                                  sx={{width: 50, height: 50}}
                                                  disabled={data?.deviceType === 'digital'}
                                                  onClick={() => updateEfosInfoHandler('digit')}
                                                >
                                                  수정
                                                </Button>
                                              </IconButton>
                                            </InputAdornment>
                                          ),
                                        }}
                                      />
                                    </div>

                                    <div
                                      className={
                                        'd-flex w-100 justify-content-between align-items-center'
                                      }
                                    >
                                      <h2 className={'d-flex align-items-center gap-2'}>
                                        <DoubleArrowIcon /> 계량기 타입
                                      </h2>
                                      <div
                                        className='d-flex align-items-center gap-3 mx-2 p-2'
                                        style={{borderRadius: '4px', border: '1px solid #ccc'}}
                                      >
                                        <FormControl variant='outlined' style={{width: 230}}>
                                          <Select
                                            id='meter-type-select'
                                            value={
                                              updateRecognizedType ||
                                              data?.recognizedType ||
                                              'analog'
                                            }
                                            onChange={(e) => {
                                              setUpdateRecognizedType(e.target.value)
                                            }}
                                          >
                                            <MenuItem value='analog'>아날로그</MenuItem>
                                            <MenuItem value='digital'>디지털</MenuItem>
                                            <MenuItem value='gas'>가스</MenuItem>
                                          </Select>
                                        </FormControl>
                                        <Button
                                          variant='outlined'
                                          size='medium'
                                          sx={{width: 50, height: 50}}
                                          onClick={() => updateRecognizedTypeHandler()}
                                        >
                                          수정
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </Paper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
                {selectedView === 'edit' && (
                  <div className='container mt-5'>
                    <div className='row'>
                      {/* 왼쪽: 이미지 영역 */}
                      <div className='col-lg-6 mb-4'>
                        <div className='card shadow-sm'>
                          <div className='card-body'>
                            <div className='d-flex align-items-center justify-content-center'>
                              <h4 className='card-title text-center'>원본 이미지</h4>
                              <CustomWidthTooltip
                                title={DeviceEditImageTooltip('original')}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 600}}
                                placement={'right-start'}
                                arrow={true}
                                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
                              >
                                <HelpIcon
                                  color={'primary'}
                                  sx={{fontSize: '24px'}}
                                  className={'ms-4 mb-2 cursor-pointer'}
                                />
                              </CustomWidthTooltip>
                            </div>
                            <canvas
                              ref={baseCanvasRef}
                              width={400}
                              height={300}
                              className='border border-secondary w-100 mb-3'
                              onMouseDown={startDrawing}
                              onMouseMove={draw}
                              onMouseUp={stopDrawing}
                              onMouseLeave={stopDrawing}
                              style={{
                                cursor: 'crosshair', // 커서를 페인트 브러쉬 스타일로 설정
                              }}
                            />

                            <div className='d-flex align-items-center justify-content-center'>
                              <h4 className='card-title text-center'>레이어</h4>
                              <CustomWidthTooltip
                                title={DeviceEditImageTooltip('overLay')}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 600}}
                                placement={'right-start'}
                                arrow={true}
                                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
                              >
                                <HelpIcon
                                  color={'primary'}
                                  sx={{fontSize: '24px'}}
                                  className={'ms-4 mb-2 cursor-pointer'}
                                />
                              </CustomWidthTooltip>
                            </div>
                            <canvas
                              ref={overlayCanvasRef}
                              width={400}
                              height={300}
                              className='border border-secondary w-100 mb-3'
                            />
                            <div className='d-flex align-items-center justify-content-center'>
                              <h4 className='card-title text-center'>필터</h4>
                              <CustomWidthTooltip
                                title={DeviceEditImageTooltip('filter')}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 600}}
                                placement={'right-start'}
                                arrow={true}
                                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
                              >
                                <HelpIcon
                                  color={'primary'}
                                  sx={{fontSize: '24px'}}
                                  className={'ms-4 mb-2 cursor-pointer'}
                                />
                              </CustomWidthTooltip>
                            </div>
                            <canvas
                              ref={openCVCanvasRef}
                              width={400}
                              height={300}
                              className='border border-secondary w-100'
                            />
                          </div>
                        </div>
                      </div>

                      {/* 오른쪽: 설정 영역 */}
                      <div className='col-lg-6'>
                        <div className='card shadow-sm'>
                          <div className='card-body'>
                            <div className='d-flex align-items-center justify-content-center'>
                              <h4 className='card-title text-center'>최종 결과 이미지 예상</h4>
                              <CustomWidthTooltip
                                title={DeviceEditImageTooltip('final')}
                                TransitionComponent={Fade}
                                TransitionProps={{timeout: 600}}
                                placement={'right-start'}
                                arrow={true}
                                sx={{fontSize: '10px', letterSpacing: '1px', cursor: 'pointer'}}
                              >
                                <HelpIcon
                                  color={'primary'}
                                  sx={{fontSize: '24px'}}
                                  className={'ms-4 mb-2 cursor-pointer'}
                                />
                              </CustomWidthTooltip>
                            </div>
                            <canvas
                              ref={resultCanvasRef}
                              width={400}
                              height={300}
                              className='border border-secondary w-100'
                            />

                            <h5 className='card-title text-center mb-4 '>레이어 설정</h5>
                            <div className='d-flex card-body shadow-sm gap-6 align-items-center justify-content-center flex-row mb-4'>
                              {/*overLay */}
                              <div className='d-flex align-items-center gap-3'>
                                <button
                                  className={`btn ${
                                    isRectangleMode ? 'btn-danger' : 'btn-primary'
                                  } btn-sm`}
                                  style={{whiteSpace: 'nowrap'}} // 줄바꿈 방지
                                  onClick={() => setIsRectangleMode(!isRectangleMode)}
                                >
                                  {isRectangleMode ? '사각형 모드 해제' : '사각형 모드'}
                                </button>

                                <label
                                  className='form-label mb-0 fw-bold'
                                  style={{
                                    fontSize: '16px',
                                    whiteSpace: 'nowrap', // 줄바꿈 방지
                                  }}
                                >
                                  색상:
                                </label>
                                <input
                                  type='color'
                                  value={overLayLine?.lineColor}
                                  onChange={(e) =>
                                    changeOverLayLineHandler('lineColor', e.target.value)
                                  }
                                  className='form-control form-control-color'
                                  style={{width: '70px'}}
                                />
                              </div>

                              <div className='d-flex align-items-center gap-3'>
                                <label
                                  className='form-label mb-0'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  선 굵기:
                                </label>
                                <input
                                  type='range'
                                  min='1'
                                  max='20'
                                  value={overLayLine?.lineWidth}
                                  onChange={(e) =>
                                    changeOverLayLineHandler('lineWidth', parseInt(e.target.value))
                                  }
                                  className='form-range'
                                  style={{width: '100%'}}
                                />
                              </div>
                            </div>
                            <h5 className='card-title text-center mb-4'>필터 설정</h5>
                            <div className='card-body shadow-sm gap-6 align-items-center justify-content-center  mb-4'>
                              <div className='mb-3'>
                                <label
                                  className='form-label'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  블러: {imageFilter?.blur}
                                </label>
                                <input
                                  type='range'
                                  min='0'
                                  max='50'
                                  step='1'
                                  value={imageFilter?.blur}
                                  onChange={(e) =>
                                    changeImageFilter('blur', parseInt(e.target.value))
                                  }
                                  className='form-range'
                                />
                              </div>
                              <div className='mb-3'>
                                <label
                                  className='form-label'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  샤프닝: {imageFilter?.sharpening}
                                </label>
                                <input
                                  type='range'
                                  min='0'
                                  max='10'
                                  step='0.1'
                                  value={imageFilter?.sharpening}
                                  onChange={(e) =>
                                    changeImageFilter('sharpening', parseFloat(e.target.value))
                                  }
                                  className='form-range'
                                />
                              </div>
                              <div className='mb-3'>
                                <label
                                  className='form-label'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  명암: {imageFilter?.contrast}
                                </label>
                                <input
                                  type='range'
                                  min='0.5'
                                  max='3'
                                  step='0.1'
                                  value={imageFilter?.contrast}
                                  onChange={(e) =>
                                    changeImageFilter('contrast', parseFloat(e.target.value))
                                  }
                                  className='form-range'
                                />
                              </div>
                              <div className='mb-3'>
                                <label
                                  className='form-label'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  밝기: {imageFilter?.brightness}
                                </label>
                                <input
                                  type='range'
                                  min='0'
                                  max='2'
                                  step='0.1'
                                  value={imageFilter?.brightness}
                                  onChange={(e) =>
                                    changeImageFilter('brightness', parseFloat(e.target.value))
                                  }
                                  className='form-range'
                                />
                              </div>
                              <div className='mb-3'>
                                <label
                                  className='form-label'
                                  style={{
                                    fontWeight: 'bold',
                                    fontSize: '18px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  감마: {imageFilter?.gamma}
                                </label>
                                <input
                                  type='range'
                                  min='0.1'
                                  max='3'
                                  step='0.1'
                                  value={imageFilter?.gamma}
                                  onChange={(e) =>
                                    changeImageFilter('gamma', parseFloat(e.target.value))
                                  }
                                  className='form-range'
                                />
                              </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                              <button onClick={deleteImageRevisionData} className='btn btn-info'>
                                보정 이미지 지우기
                              </button>
                              <button onClick={clearCanvas} className='btn btn-danger'>
                                레이어 지우기
                              </button>
                              <button
                                onClick={() => resetImageFilter()}
                                className='btn btn-warning'
                              >
                                필터 초기화
                              </button>
                              <button onClick={saveFilterSetting} className='btn btn-primary'>
                                저장
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </Box>
    </Modal>
  )
}
export default DeviceDetailInfoModal
