import React, {useEffect, useState} from 'react'
import {Outlet, Route, Routes, useNavigate} from 'react-router-dom'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import OfficeConfigHeader from './layout/officeConfigHeader'
import OfficeConfigSetup from './component/officeConfigSetup'
import {axiosWithAuth} from '../../helper/axiosWithAuth'
import {useSelector} from 'react-redux'
import {useAuth} from '../../_component/modules/auth'

/* component */

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: '내 계정관리',
    path: '/pages/account',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const CustomerPage = (props: any) => {
  const {toast} = props
  const [officeList, setOfficeList] = useState<any>()
  const [flag, setFlag] = useState<boolean>(false)
  const {logout} = useAuth()
  const navigate = useNavigate()
  const userInfo = useSelector((state: any) => state.userInfo?.userInfo)
  const getOfficeList = () => {
    axiosWithAuth()
      .get(`/office/${userInfo?.officeId}`)
      .then((res: any) => {
        setOfficeList(res?.data?.res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getOfficeList()
  }, [])
  useEffect(() => {
    getOfficeList()
  }, [flag])
  useEffect(() => {
    if (
      userInfo === null ||
      (userInfo.accountType !== 'sys_admin' &&
        userInfo.accountType !== 'sys_user' &&
        userInfo.accountType !== 'gov_admin' &&
        userInfo.accountType !== 'charge_user')
    ) {
      navigate('/auth')
      logout()
    }
  }, [])

  return (
    <Routes>
      <Route
        element={
          <>
            <OfficeConfigHeader
              toast={toast}
              officeList={officeList}
              setFlag={setFlag}
              flag={flag}
            />
            <Outlet />
          </>
        }
      >
        <Route
          path={'setup'}
          element={
            <>
              <PageTitle breadcrumbs={profileBreadCrumbs}>기초정보</PageTitle>
              <OfficeConfigSetup
                toast={toast}
                officeList={officeList}
                getOfficeList={getOfficeList}
                setFlag={setFlag}
                flag={flag}
              />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default CustomerPage
