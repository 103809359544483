import {globalConst} from 'utils/const/globalConst'

interface toolTipsType {
  description: String
  text: React.ReactNode
}

interface toolTipTextType {
  customerRegister: toolTipsType
  customerAllocation: toolTipsType
  customerInfo: toolTipsType
  customerActivity: toolTipsType
  customerCheckList: toolTipsType
  customerCheckReport: toolTipsType
  customerMinimumFlow: toolTipsType
  customerStatus: toolTipsType
  customerLeakage: toolTipsType
  deviceRegister: toolTipsType
  deviceAllocation: toolTipsType
  deviceInfo: toolTipsType
  deviceImages: toolTipsType
  deviceHistory: toolTipsType
  asManagement: toolTipsType
  recoveryInfo: toolTipsType
  account: toolTipsType
  customerUnused: toolTipsType
  customerInspection: toolTipsType
  customerFreezeWarning: toolTipsType
  dashboardFreezeWarning: toolTipsType
}

export const toolTipText: toolTipTextType = {
  customerRegister: {
    description: '수용가 등록을 진행합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>수용가 등록</span>
        <span className={'fs-5'}>● 개별등록과 일괄등록을 진행 할 수 있습니다.</span>
        <span className={'fs-5'}>
          ● 주소 검색을 통해 도로명 주소와 지번 주소를 입력 할 수 있습니다.
        </span>
        <span className={'fs-5'}>● 담당자를 입력하여 모니터링 할 수 있습니다.</span>
        <span className={'fs-5'}>● 특이사항이 있는 수용가에 메모를 입력 할 수 있습니다.</span>
      </div>
    ),
  },
  customerAllocation: {
    description: '수용가를 할당합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>수용가 할당</span>
        <span className={'fs-5'}>● 설치 가능한 수용가에 대한 할당을 관리합니다.</span>
        <span className={'fs-5'}>● 할당된 수용가에 대해 추가, 취소할 수 있습니다.</span>
        <span className={'fs-5'}>● 할당및 설치 가능 여부에 따라 수용자를 검색할 수 있습니다.</span>
        <span className={'fs-5'}>● 필요에 따라 수용가의 할당을 삭제 할 수 있습니다.</span>
      </div>
    ),
  },
  customerInfo: {
    description: '수용가의 정보를 조회합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>수용가 정보 관리</span>
        <span className={'fs-5'}>● 수용가의 정보를 확인, 관리 가능합니다.</span>
        <span className={'fs-5'}>
          ● 연결된 단말기의 정보를 포함, 수용가의 데이터를 열람할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 검색항목을 기준으로 수용가를 필요한 정보를 빠르게 찾을 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● A/S 등록 및 취소 기능으로 수용가에 대한 서비스 관리를 용이하게 합니다.
        </span>
        <span className={'fs-5'}>● 수용가 정보를 엑셀 파일로 내려 받는 기능을 제공합니다.</span>
      </div>
    ),
  },
  customerActivity: {
    description: '지속적인 모니터링으로 사회복지 서비스를 제공합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>건강 모니터링</span>
        <span className={'fs-5'}>
          ● 수용가 종류를 '노인'으로 설정한 데이터에 대한 정보를 제공합니다.
        </span>
        <span className={'fs-5'}>
          ● 독거 노인들의 생활 패턴을 자원 사용량을 모니터링하여 그들의 안전,복지를 확인합니다.
        </span>
        <span className={'fs-5'}>
          ● 일관되거나 지속적인 자원 사용 패턴이 감지 될 경우, 해당 수용가의 위험 상태를 암시할 수
          있으며, 적절한 조치를 취할 수 있도록 경고합니다.
        </span>
        <span className={'fs-5'}>
          ● 사용량 데이터는 시각적으로 명확한 그래프 형태로 표시됩니다.
        </span>
        <span className={'fs-5'}>
          ● 날짜별로 데이터를 검색 하여, 특정 시기의 사용량 변화를 분석 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 이 페이지는 독거노인들의 건강과 안전을 지키는 데 중요한 역할을 하며, 지역 사회의
          보호망을 강화하는 데 기여합니다.
        </span>
      </div>
    ),
  },
  customerCheckList: {
    description: '수용가의 검침내역을 기간 별로 조회 할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>수용가 검침조회</span>
        <span className={'fs-5'}>
          ● 기간별 검침 이미지 확인 및 검침 간의 사용량을 조회 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 특정 기간 동안의 검침 이미지를 열람하여, 수용가의 검침 상태를 시각적으로 확인
          가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 전 검침값부터 다음 검침값까지의 총 사용량을 확인할 수 있어, 수용가별 소비 패턴을 분석,
          관리에 유용합니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 수용가의 에너지 사용 효율성을 평가하고, 필요한 경우 조정을 위한 근거를 제공받을
          수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 에너지 관리와 소비 효율성 향상에 중요한 역할을 하는 도구로, 정확한 검침 데이터의 확인과
          분석을 용이하게 합니다.
        </span>
      </div>
    ),
  },
  customerCheckReport: {
    description: '전체 수용가의 검침일에 맞는 리포트를 확인할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>검침 리포트</span>
        <span className={'fs-5'}>● 검침일에 해당하는 검침값을 확인하고 관리할 수 있습니다.</span>
        <span className={'fs-5'}>
          ● 사용자가 전월 및 당월 검침값을 직접 확인하고 필요에 따라 수정할 수 있는 기능을
          제공합니다.
        </span>
        <span className={'fs-5'}>
          ● 검침 데이터의 정확성을 보장하고, 필요시 즉각적인 조정을 가능하게 합니다.
        </span>
        <span className={'fs-5'}>
          ● 특히, 당월 검침일이 아직 도래하지 않아 검침 이미지가 없는 경우,
          <span className={'text-danger'}>'검침 데이터 수집 중입니다.'</span>으로 명확히 표시됩니다.
        </span>
        <span className={'fs-5'}>
          ● 검침 데이터의 정확성과, 시의성을 확보하여 사용자가 현재 검침 상태를 쉽게 이해할 수
          있도록 돕습니다.
        </span>
      </div>
    ),
  },
  customerMinimumFlow: {
    description: '설정한 시간에 사용량이 있는 수용가 정보를 조회합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>최소유량 조회</span>
        <span className={'fs-5'}>
          ● 주로 야간 시간대에 누수 발생 여부를 탐지하는 데 중점을 두고 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 특정 시간대의 데이터를 확인하여, 물 사용 패턴을 분석, 이상 유량을 식별할 수
          있습니다.
        </span>
        <span className={'fs-5'}>
          ● 주로 야간에 발생할 수 있는 누수를 조기에 발견, 대응하는 데 큰 도움이 됩니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 원하는 시간대를 선택하여 해당 기간 동안의 사용량 및 검침 데이터를 상세히 조회할
          수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● <span className={'fw-bold fs-4'}>'사용량 존재여부'</span> 스위치를 이용하여 사용량이
          실제로 존재하는 수용가들의 데이터만 필터링 하여, 보다 효율적인 데이터 관리가 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 수용가의 물 사용 패턴을 모니터링, 필요한 조치를 취하기 위한 중요한 정보를 제공합니다.
        </span>
      </div>
    ),
  },
  customerStatus: {
    description: '수용가 현황을 조회합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>수용가 현황</span>
        <span className={'fs-5'}>
          ● 수용가 번호를 클릭하여 해당 수용가의 상세 정보를 열람할 수 있으며, 필요한 경우 기본적인
          정보를 수정할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 검침 이미지의 기록들을 통해, 수용가의 검침 데이터를 시각적으로 확인할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 이미지 클릭시, 표 형식으로 제공되는 단말기의 배터리 정보,온도 등의 상세 데이터를 확인할
          수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 이 페이지는 관리자들이 수용가의 상태를 효과적으로 관리하고, 필요한 정보를 신속하게
          접근할 수 있도록 설계되었습니다.
        </span>
      </div>
    ),
  },
  customerLeakage: {
    description: '누수가 의심되는 수용가의 정보를 조회합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>누수의심 조회</span>
        <span className={'fs-5'}>
          ● 날짜를 선택하여 누수가 의심되는 수용가의 사용량을 확인 할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 해당 날짜를 기준으로 설정된 주기 전의 측정된 사용량과 해당 날짜의 측정된 사용량으로
          누수의심 측정값를 예측하며, 예측된 누수의심 측정값보다 측정된 사용량이 많은 수용가를 조회
          합니다.
        </span>
        <span className={'fs-5'}>
          ● 이 페이지를 통해 예측 사용량과 실제 사용량의 큰 차이가 나는 수용가를 조회하여 필요한
          조치를 취하기 위한 정보를 제공합니다.
        </span>
      </div>
    ),
  },
  deviceRegister: {
    description: '단말기 등록을 진행합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>단말기 등록</span>
        <span className={'fs-5'}>● 개별등록과 일괄등록을 진행 할 수 있습니다.</span>
        <span className={'fs-5'}>
          ● 해당하는 단말기 타입이 없는 겅우 우측의 단말기 타입 버튼 추가를 통해 추가 가능합니다.
        </span>
        <span className={'fs-5'}>● 월 별로 추가된 단말기의 등록 현황을 확인 가능합니다.</span>
      </div>
    ),
  },
  deviceAllocation: {
    description: '단말기의 검수 및 할당을 조회 할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>검수/할당 관리</span>
        <span className={'fs-5'}>
          ● 검수 조회 페이지에서 단말기 등록 후 해당 단말기가 송신한 이미지를 통해 승인 상태를
          관리합니다.
        </span>
        <span className={'fs-5'}>
          ● 검색 항목 필터링을 통해 특정한 단말기의 정보를 확인 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● <span className={'text-primary'}>'IMEI'</span>행을 클릭 시, 해당 단말기의 상세 정보를
          확인 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 할당 조회 페이지에서 검수 완료된 단말기를 설치자에게 할당 가능합니다.
        </span>
      </div>
    ),
  },
  deviceInfo: {
    description: '미터리움(METERARIUM) 단말기 정보를 보여줍니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>단말기 정보 관리</span>
        <span className={'fs-5'}>● 단말기의 상세 정보를 중심적으로 다룹니다.</span>
        <span className={'fs-5'}>
          ● 사용자는 단말기의 이미지 히스토리를 비롯해 중요한 기술적 세부사항을 확인할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 특히 단말기의 전파 세기, 최초 등록일자, 단말기 펌웨어 및 검침기 펌웨어 정보 등이 명시
          되어 사용자가 쉽게 접근 및 분석이 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 단말기의 성능 및 상태를 효과적으로 제공하고 모니터링에 필요한 모든 정보를 제공함으로써,
          시스템의 안정성, 효율성을 지속적으로 유지 가능하게 합니다.
        </span>
      </div>
    ),
  },
  deviceImages: {
    description: '단말기에서 수신하는 이미지를 조회할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>단말기 수신영상 관리</span>
        <span className={'fs-5'}>
          ● 미터리움 시스템에 수신되는 모든 단말기의 정보를 조회하고 관리합니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 '정상처리', '인식 실패', '자리수 인식 오류', '인식 대기 중', '비정상 이미지',
          '검침값이 수정된 이미지'등의 카테고리별로 분류된 단말기 검침 결과를 열람할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ●사용자는 각 단말기의 현재 상태, 검침 데이터의 정확성을 파악할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 기간별로 수신된 이미지를 조회하는 기능을 제공하며, 단말기 활동과 상태 변화를 분석할 수
          있습니다.
        </span>
        <span className={'fs-5'}>
          ● 미터리움 시스템의 효과적인 모니터링과 관리에 필수적인 도구로, 데이터의 정확성과,
          시스템의 신뢰성을 유지하는데 중요한 역할을 합니다.
        </span>
      </div>
    ),
  },
  deviceHistory: {
    description: '단말기의 모든 기록들을 확인할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>단말기 History</span>
        <span className={'fs-5'}>● 미터리움에 등록된 모든 단말기의 기록을 확인 가능합니다. </span>
        <span className={'fs-5'}>
          ● 각 단말기 별 단말기 버전, 서버로 데이터 전송 주기, 전파 강도등을 확인 가능합니다.
        </span>
      </div>
    ),
  },
  asManagement: {
    description: 'A/S 조회 및 등록을 할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>A/S 관리</span>
        <span className={'fs-5'}>● 서비스 요청에 대한 A/S 작업을 관리, 조정 합니다.</span>
        <span className={'fs-5'}>
          ● A/S 작업을 새로 배정하거나 기존 배정을 취소하는 기능을 제공합니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 원인명, 해결명, 결과, 그리고 A/S 할당 상태와 같은 다양한 기준에 따라 A/S 요청을
          조회할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 사용자는 서비스 요청의 상태를 신속히 파악하고, 필요에 따라 적절한 A/S 작업을 할당하거나
          변경할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 서비스 요청의 효율적인 처리와 고객 만족도 향상을 위한 중요한 도구로서, 서비스 품질
          관리에 핵심적인 역할을 합니다.
        </span>
      </div>
    ),
  },
  recoveryInfo: {
    description: '단말기의 회수 상태를 조회 할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>회수 조회</span>
        <span className={'fs-5'}>
          ● 설치된 단말기들을 회수하여 검수 및 재생 과정을 진행하는 페이지입니다.
        </span>
        <span className={'fs-5'}>
          ● 현장에서 사용되고 있는 단말기들의 회수 상태를 추적하고 관리하는 데 중점을 두고 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 회수된 단말기들의 목록을 확인할 수 있으며, 각 단말기의 현재 상태와 검수 진행 상황을
          상세히 파악할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 단말기가 효율적으로 재생, 재사용 전 필요한 검수 작업이 적절히 이루어지고 있는지
          모니터링할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 단말기의 수명 주기 관리와 자원의 효율적 사용을 지원하며, 지속 가능한 운영 관행을
          촉직하는 역할을 합니다.
        </span>
      </div>
    ),
  },
  account: {
    description: '사용자 등록과 그룹관리를 할 수 있습니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>사용자 계정 관리</span>
        <span className={'fs-5'}>● 회원 가입한 모든 계정의 관리가 가능합니다.</span>
        <span className={'fs-5'}>
          ● 관리자는 사용자들의 계정을 수정하거나 이미지 URL을 업데이트할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 새로운 계정을 생성하고 계정 타입을 추가, 삭제, 수정등의 작업을 진행 가능합니다.
        </span>
        <span className={'fs-5'}>
          ● 검색 기능을 통해 권한과 활성화 상태를 기준으로 특정 사용자를 빠르고 쉽게 찾아볼 수
          있습니다.
        </span>
        <span className={'fs-5'}>
          ● 시스탬 내에서 다양한 역할과 책임을 가진 사용자들을 효과적으로 관리하기 위한 중심적인
          도구로 가능합니다.
        </span>
      </div>
    ),
  },
  customerUnused: {
    description: '장기미사용중인 수용가를 조회 합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>장기미사용</span>
        <span className={'fs-5'}>● 검침값이 일정 기간동안 동일한 수용가를 조회힙니다.</span>
        <span className={'fs-5'}>
          ● 관리자는 사업소 기초설정 페이지에서 해당 기간을 수정 할 수 있습니다.
        </span>
        <span className={'fs-5'}>
          ● 장기미사용 조회 페이지를 통해 장시간미사용인 수용가에 대한 조치를 취할 수 있습니다.
        </span>
      </div>
    ),
  },
  customerInspection: {
    description: '검침값 확인이 필요한 수용가를 조회 합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>점검</span>
        <span className={'fs-5'}>● 정해진 기간동안 검침값이 감소한 수용가를 조회 합니다.</span>
        <span className={'fs-5'}>
          ● 실제 사용량이 아닌 이미지 인식 재확인이 필요한 수용가 중점으로 조회 가능합니다.
        </span>
      </div>
    ),
  },
  customerFreezeWarning: {
    description: '동파위험이 있는 수용가를 조회 합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-3 fw-bold'}>동파위험 조회</span>
        <span className={'fs-5'}>
          ● 계량기내 설치되는 온도센서를 통해 온도를 측정하고 조회 합니다.
        </span>
        <span className={'fs-5'}>
          ● -1°C 초과 (양호), -1°C ~ -3°C (주의), -3°C ~ -5°C (위험), -5°C미만 (매우위험) 4단계로
          구분 합니다.
        </span>
        <span className={'fs-5'}>
          ● 정확한 온도 모니터링으로 동절기 계량기 동파를 사전 방지 하며, 동파 이후 발생되는 계량기
          교체, 및 행정적인 비용, 물 사용자 민원에 대한 선제적 대응조치를 취할 수 있습니다.
        </span>
      </div>
    ),
  },
  dashboardFreezeWarning: {
    description: '동파위험이 있는 수용가를 조회 합니다.',
    text: (
      <div className={'row gap-3 p-2'}>
        <span className={'fs-5'}>
          ● -1°C 초과 (양호), -1°C ~ -3°C (주의), -3°C ~ -5°C (위험), -5°C미만 (매우위험) 4단계로
          구분 합니다.
        </span>
      </div>
    ),
  },
}

export const installDevicedBmToolTip = (type: any) => {
  switch (type) {
    case 'N':
      return (
        <>
          <div className={'fs-4 px-3 gap-3'}>
            <div className={'fw-bold alert alert-danger text-center row'}>
              <span>
                전파 세기가 {globalConst.rssiMinimum}dBm ~ {globalConst.rssiMaximum}dBm인 경우
              </span>
              <span>검침값 전송에 문제가 생길 수 있습니다.</span>
              <span>다음과 같이 설치를 진행 해주시기 바랍니다.</span>
            </div>
            <div className={'fw-bold alert alert-dark text-center row'}>
              <span>외장 안테나 설치 후 전파세기 재확인</span>
            </div>
          </div>
        </>
      )

    case 'OUT':
      return (
        <>
          <div className={'fs-4 px-3 gap-3'}>
            <div className={'fw-bold alert alert-danger text-center row'}>
              <span>
                전파 세기가 {globalConst.rssiMinimum}dBm ~ {globalConst.rssiMaximum}dBm인 경우
              </span>
              <span>검침값 전송에 문제가 생길 수 있습니다.</span>
              <span>다음과 같이 설치를 진행 해주시기 바랍니다.</span>
            </div>
            <div className={'fw-bold alert alert-dark text-center row'}>
              <span>설치 전에 담당자에게 연락하여</span>
              <span>설치 여부를 확인 해주시기 바랍니다.</span>
            </div>
          </div>
        </>
      )
    case 'success':
      return (
        <>
          <div className={'fw-bold fs-4 px-3 gap-3'}>
            <div className={'fw-bold alert alert-success text-center row'}>
              <span>전파 세기가 검침기를 설치하는데 지장이 없습니다.</span>
              <span>주변에 전파방해 요소 있을 시 외부 설치로 진행</span>
            </div>
          </div>
        </>
      )
    default:
      return <></>
  }
}

export const DeviceEditImageTooltip = (type: any) => {
  switch (type) {
    case 'original':
      return (
        <div className={'row gap-3 p-2'}>
          <span className={'fs-5'}>1. 레이어 설정을 통해 색, 굵기를 설정해주세요.</span>
          <span className={'fs-5'}>
            2. 원본 이미지에 숫자 영역을 제외한 빛 번짐으로 예상되는 구역을 칠해주세요.
          </span>
        </div>
      )
    case 'overLay':
      return (
        <div className={'row gap-3 p-2'}>
          <span className={'fs-5'}>
            1. 원본 이미지에 칠한 색이 표현되며, 직접 칠은 불가능합니다.
          </span>
          <span className={'fs-5'}>2. 저장 시 해당 레이어만 저장됩니다.</span>
          <span className={'fs-5'}>3. 원본 이미지를 변경하지 않습니다.</span>
        </div>
      )

    case 'filter':
      return (
        <div className={'row gap-3 p-2'}>
          <span className={'fs-5'}>1. 필터 설정 값을 변경해주세요</span>
          <span className={'fs-5'}>
            2. 해당 필터 설정을 반영하여 원본이미지에 필터를 적용합니다.
          </span>
          <span className={'fs-5'}>3. 저장 시 해당 필터 설정값만 저장됩니다. </span>
          <span className={'fs-5'}>4. 원본 이미지를 변경하지 않습니다.</span>
        </div>
      )
    case 'final':
      return (
        <div className={'row gap-3 p-2'}>
          <span className={'fs-5'}>1. 레이어와, 필터 이미지를 결합한 이미지입니다.</span>
          <span className={'fs-5'}>
            2. 실제 이미지 인식 시 적용 될 이미지를 예상한 이미지입니다.
          </span>
          <span className={'fs-5'}>
            3. 수용가 정보, 수용가 현황 페이지에서는 필터를 제외한, 이미지를 확인 할 수 있습니다.
          </span>
        </div>
      )
    default:
      return <></>
  }
}
