import MenuIcon from '@mui/icons-material/Menu'
import {AppBar, Button, IconButton, Menu, MenuItem, Toolbar} from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {envBackHost, serverLocalName} from 'GLOBAL/envVariables'
import _ from 'lodash'
import React from 'react'
import {Link, useLocation} from 'react-router-dom'
const InstallHeader = (props: any) => {
  const {count, getPosition} = props
  const location = useLocation()
  const locationName = (name: string) => {
    switch (location?.pathname) {
      case '/install/connect':
        return '설치'
      case '/install/status':
        return '설치완료'
      case '/install/as':
        return 'A/S'
      case '/install/as/complete':
        return 'A/S완료'
    }
  }
  const label = [
    {krName: '설치', location: '/install/connect', count: count?.installAvailableCount},
    {krName: '설치완료', location: '/install/status', count: count?.installCompleteCount},
    {krName: 'A/S', location: '/install/as', count: count?.installAsCount},
    {krName: 'A/S완료', location: '/install/as/complete', count: count?.installAsCompleteCount},
  ]
  const filteredLabels = label.filter((item) => item.location !== location.pathname)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const reLocateHandler = (serverName: any) => {
    switch (serverName) {
      case 'prod':
      case 'gyeryong':
      case 'mokpo':
      case 'suncheon':
      case 'dev':
        return true
      default:
        return false
    }
  }

  return (
    <>
      <Box sx={{marginBottom: 1.5, padding: 0}}>
        <AppBar position='static'>
          <Toolbar>
            <IconButton
              id='basic-button'
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
              size='large'
              edge='start'
              color='inherit'
              aria-label='menu'
              sx={{mr: 2}}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant='h1' component='div' sx={{flexGrow: 1, fontSize: 20}}>
              <span>{locationName(location?.pathname)} </span>
              <span>( {_.find(label, {location: location?.pathname})?.count} 건 )</span>
            </Typography>
            {reLocateHandler(serverLocalName) && (
              <Button
                color='warning'
                onClick={() => {
                  getPosition()
                }}
              >
                GPS 재검색
              </Button>
            )}

            <Button
              color='inherit'
              onClick={() => {
                window.open(`${envBackHost}/install/pdf`, '_blank')
              }}
            >
              매뉴얼
            </Button>
          </Toolbar>
        </AppBar>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {_.map(filteredLabels, (item, index) => {
            return (
              <MenuItem key={index} onClick={handleClose}>
                <Link to={item.location} className={'nav-link d-flex gap-3'}>
                  <span>{item?.krName}</span>
                  <span>( {item?.count} 건 )</span>
                </Link>
              </MenuItem>
            )
          })}
        </Menu>
      </Box>
    </>
  )
}
export default InstallHeader
